'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.contacts
 # @description

###
angular
  .module 'mundoAdmin.contacts', [
    'ui.router'
  ]
  .run [
    'MundoNavigation'
    (MundoNavigation) ->
      MundoNavigation.addSection
        id: 'contacts'
        state: 'contacts'
        label: 'app.admin.contacts'
        icon: 'contact_phone'
        showExpression: 'hasPermission("access contact section")'
        items: [
            state: 'contacts.overview'
            label: 'app.admin.pageTitles.contacts.contacts'
            icon: 'contact_phone'
            showExpression: 'hasPermission("view all MundoMosaContactBundle:Contact entities")'
          ,
            state: 'contacts.types'
            label: 'app.admin.pageTitles.contacts.contactTypes'
            icon: 'contacts'
            showExpression: 'hasPermission("view all MundoMosaContactBundle:Contact entities")'
        ]
  ]
